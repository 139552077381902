module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-513b7f76b2/4/.yarn/berry/cache/gatsby-plugin-manifest-npm-5.14.0-8a89b421cf-10c0.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"surva network","short_name":"surva","start_url":"/","background_color":"#ffffff","theme_color":"#adadd6","display":"minimal-ui","icon":"src/images/surva-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5817f36b0bc40070a2cdaa027a5b133f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-beb5e5c668/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
